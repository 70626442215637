import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lang-switcher',
  standalone: true,
  imports: [CommonModule, MatMenuModule, TranslateModule],
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LangSwitcherComponent {
  translateService = inject(TranslateService);
  currentLang = this.translateService.currentLang;
  languages = [
    {
      id: 'us',
      name: 'English'
    },
    {
      id: 'de',
      name: 'German'
    }
  ];

  constructor() {
    const lang = localStorage.getItem('lang');
    if (lang) {
      this.currentLang = lang;
      this.translateService.use(lang);
    }
  }

  switchLanguage(newLang: string) {
    this.translateService.use(newLang);
    this.currentLang = this.translateService.currentLang;
    localStorage.setItem('lang', newLang);
  }
}
