import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';
import { BrowserUtils } from '@azure/msal-browser';

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
