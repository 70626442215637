import { Route } from '@angular/router';

export const ROUTES: Route[] = [
  // {
  //   path: '',
  //   loadComponent: () =>
  //     import('./features/query/feature/query-feature.component').then(
  //       mod => mod.QueryFeatureComponent
  //     )
  // },
  {
    path: 'intelligent-search',
    loadComponent: () =>
      import(
        './features/intelligent-search/feature/intelligent-search-feature.component'
      ).then(mod => mod.IntelligentSearchFeatureComponent)
  },
  {
    path: 'keyword-search',
    loadComponent: () =>
      import(
        './features/keyword-search/feature/keyword-search-feature.component'
      ).then(mod => mod.KeywordSearchFeatureComponent)
  },
  {
    path: 'document-chatting',
    loadComponent: () =>
      import(
        './features/document-chatting/feature/document-chatting-feature.component'
      ).then(mod => mod.DocumentChattingFeatureComponent)
  },
  {
    path: 'chatbot',
    loadComponent: () =>
      import('./features/chatbot/feature/chatbot-feature.component').then(
        mod => mod.ChatbotFeatureComponent
      )
  },
  {
    path: 'document-generator',
    loadComponent: () =>
      import(
        './features/document-generator/feature/document-generator-feature.component'
      ).then(mod => mod.DocumentGeneratorFeatureComponent)
  },
  {
    path: 'experimental-features',
    loadComponent: () =>
      import(
        './features/experimental-features/feature/experimental-features-feature.component'
      ).then(mod => mod.ExperimentalFeaturesFeatureComponent)
  },
  {
    path: '**',
    redirectTo: 'intelligent-search'
  }
];
