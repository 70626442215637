import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { StateService } from '../../../core/data-access/state.service';
import { History } from '../model/history.model';

@Component({
  selector: 'app-query-history',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule],
  templateUrl: './query-history.component.html',
  styleUrls: ['./query-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryHistoryComponent {
  history$ = this.stateService.history$;

  constructor(private stateService: StateService) {}

  selectAnswer(history: History) {
    this.stateService.selectAnswer(history);
  }
}
