<div class="wrapper">
  <app-environment-notification></app-environment-notification>
  <app-header
    [isLogin]="!this.isActiveAccount"
    [activeAccountName]="activeAccountName"
    (authChange)="onAuthEvent($event)"></app-header>

  <mat-sidenav-container (backdropClick)="toggleSideNav(false)">
    <mat-sidenav
      [mode]="(isTabletLandscapeUp$ | async) ? 'side' : 'over'"
      [opened]="(isSideNavOpen$ | async)!">
      <button
        *ngIf="isSideNavOpen$ | async"
        class="sidenav-close-button"
        mat-icon-button
        color="primary"
        (click)="toggleSideNav(false)">
        <mat-icon>close</mat-icon>
      </button>
      <div class="sidenav-container">
        <h2>{{ 'settings.title' | translate }}</h2>

        <div class="settings-history-container scrollbar">
          <app-settings></app-settings>

          <app-query-history></app-query-history>
        </div>

        <app-release-notes></app-release-notes>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <button
        *ngIf="(isSideNavOpen$ | async) === false"
        class="sidenav-open-button"
        mat-icon-button
        color="primary"
        (click)="toggleSideNav(true)">
        <mat-icon>arrow_forward_ios</mat-icon>
      </button>
      <div class="menu-container">
        <!--        TODO ENV_TOGGLE remove if to enable in production-->
        <!--        <div *ngIf="environment.name !== 'production'" class="button-row">-->
        <div class="button-row">
          <button
            mat-flat-button
            class="button-border"
            routerLink="intelligent-search"
            routerLinkActive="mat-primary">
            {{ 'app.intelligentSearch' | translate }}
          </button>
          <button
            mat-flat-button
            class="button-border"
            routerLink="keyword-search"
            routerLinkActive="mat-primary"
            [routerLinkActiveOptions]="{ exact: true }">
            {{ 'app.intelligentMatch' | translate }}
          </button>

          <button
            mat-flat-button
            class="button-border"
            routerLink="document-chatting"
            routerLinkActive="mat-primary"
            [routerLinkActiveOptions]="{ exact: true }">
            {{ 'app.documentChatting' | translate }}
          </button>

          <button
            mat-flat-button
            class="button-border"
            routerLink="document-generator"
            routerLinkActive="mat-primary"
            [routerLinkActiveOptions]="{ exact: true }">
            {{ 'app.documentGenerator' | translate }}
          </button>

          <button
            mat-flat-button
            class="button-border"
            routerLink="experimental-features"
            routerLinkActive="mat-primary"
            [routerLinkActiveOptions]="{ exact: true }">
            {{ 'app.experimentalFeatures' | translate }}
          </button>
        </div>
        <mat-divider></mat-divider>
      </div>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
