<h2 mat-dialog-title>{{ data.title }} - {{ data.version }}</h2>
<mat-dialog-content class="mat-typography">
  <markdown>__{{ data.backend }}__</markdown>
  <markdown>{{ data.description }}</markdown>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close color="primary">
    {{ data.closeButtonText }}
  </button>
</mat-dialog-actions>
