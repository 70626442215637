import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  Answer,
  Document,
  Source,
  OpenSourceLLMAnswer,
  RequestState
} from '../../../shared/model/answer.model';
import {
  QueryBody,
  QuerySettings
} from 'src/app/shared/model/query-body.model';
import { QueryFilter } from 'src/app/shared/model/query-filter.model';
import { TranslateService } from '@ngx-translate/core';
import { AccessTokenService } from 'src/app/core/data-access/access-token.service';

@Injectable({
  providedIn: 'root'
})
export class QueryService {
  private httpClient = inject(HttpClient);
  private accessTokenService = inject(AccessTokenService);
  private translate = inject(TranslateService);
  private worker: Worker;

  answerSubject = new BehaviorSubject<Answer>(new Answer());
  answer$ = this.answerSubject.asObservable();
  historySubject = new BehaviorSubject<History[]>([]);
  history$ = this.historySubject.asObservable();
  requestState = this.setRequestInitialState();
  historyUpdated$ = new BehaviorSubject<Answer>(new Answer());
  requestInProgress$ = new BehaviorSubject<boolean>(false);
  stopStreaming$ = new BehaviorSubject<boolean>(false);
  private abortController: AbortController = new AbortController();

  intelligentSearchRunTimeStart = 0;

  constructor() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('./query.worker.ts', import.meta.url));

      this.worker.onmessage = ({ data }) => {
        if (data === 'finished') {
          const noRequestInProgress = !this.requestState.requestInProgress;
          const stopStreaming = this.stopStreaming$.getValue();
          if (noRequestInProgress && stopStreaming) {
            this.requestState.runtimeInMillis =
              Date.now() - this.intelligentSearchRunTimeStart;
            this.requestState.requestInProgress = false;
            this.requestInProgress$.next(false);
            this.stopStreaming$.next(false);
          }
        } else {
          this.requestState.answerText += data;
        }

        this.updateAnswer();
      };
    } else {
      console.log('Web Workers are not supported in your environment.');
    }
  }

  setRequestInitialState(): RequestState {
    return {
      userQuery: '',
      documents: [],
      sources: [],
      stream: true,
      answerText: '',
      requestInProgress: true,
      hasProcessedDocs: false,
      error_code: null,
      runtimeInMillis: undefined,
      index: '',
      prompt: null
    };
  }

  createSources(docs: Document[]): Source[] {
    const sources: Source[] = [];
    docs.forEach(doc => {
      sources.push({
        name: doc.meta.name,
        link: doc.meta.url
      });
    });
    return sources;
  }

  async fetchData(
    userQuery: string,
    querySettings: Partial<QuerySettings>,
    queryFilters?: Partial<QueryFilter>
  ): Promise<Response> {
    const newAbortController = new AbortController();
    this.abortController.abort();
    this.abortController = newAbortController;

    const url = `${environment.haystackBackendUrl}/get_answer`;
    queryFilters = this.checkQueryFilters(queryFilters);

    const body: QueryBody = {
      answer_type: querySettings?.answerType,
      filters: queryFilters ?? undefined,
      generator_number: 1,
      hybrid_search: querySettings?.isHybridSearch ?? undefined,
      index: querySettings?.index,
      model: querySettings?.model,
      retriever_number: querySettings?.retriever_number,
      second_index: querySettings?.second_index ?? undefined,
      stream: querySettings?.stream ?? true,
      summarization: querySettings?.isSummarization ?? undefined,
      temperature: querySettings?.temperature,
      user_id: '',
      user_query: userQuery,
      top_k: querySettings.top_k ?? undefined
    };

    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.accessTokenService.accessToken$.value
      },
      body: JSON.stringify(body),
      signal: newAbortController.signal
    });
  }

  getTextFromFoundFiles(dataBuffer: string, isArabic: boolean): string {
    const [docsString, remainingText] = dataBuffer.split('__DOCS_END__');
    const docs = JSON.parse(docsString.replace(/^data: /, ''));
    this.updateRequestState({
      documents: docs,
      hasProcessedDocs: true,
      answerText: isArabic
        ? '📚 وجدنا بعض الوثائق المتميزة التي تتناسب بشكل وثيق مع سؤالك! إليك قائمتها أدناه. فقط لتعلم، لقد استخدمنا هذه الوثائق تحديداً لتعزيز بيانات الذكاء الاصطناعي لدينا، مما يضمن لك الحصول على إجابة مصممة خصيصاً لك. تابع معنا! 🕐'
        : this.translate.instant('query.foundFiles')
    });
    this.updateAnswer();
    return remainingText;
  }

  //const noAnswer = dataBuffer.indexOf('[NO_ANSWER_FOUND]');
  //if (noAnswer !== -1) {
  replaceNoneFoundTags(dataBuffer: string) {
    if (
      dataBuffer.includes('[NO_ANSWER_FOUND]') ||
      dataBuffer.includes('_ANSWER_FOUND]') ||
      dataBuffer.includes('[NO')
    ) {
      dataBuffer = dataBuffer.replace('[NO_ANSWER_FOUND]', '');
      dataBuffer = dataBuffer.replace('[NO', '');
      dataBuffer = dataBuffer.replace('_ANSWER_FOUND]', '');
      this.updateAnswer();
    }
    return dataBuffer;
  }

  stopStreaming() {
    this.stopStreaming$.next(true);
    this.worker.postMessage({ stopStreaming: true });
  }

  processChar = async (char: string, delay: number) => {
    this.requestInProgress$.next(true);
    this.requestState.requestInProgress = true;
    return new Promise<void>((resolve, reject) => {
      try {
        if (!this.stopStreaming$.getValue()) {
          this.worker.postMessage({
            char,
            delay,
            stopStreaming: this.stopStreaming$.getValue()
          });
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  async handleStreamedData(
    userQuery: string,
    querySettings: Partial<QuerySettings>,
    isArabic: boolean,
    queryFilters?: Partial<QueryFilter>
  ) {
    const requestAlreadyInProgress = this.requestInProgress$.getValue();
    this.stopStreaming$.next(requestAlreadyInProgress);
    try {
      this.intelligentSearchRunTimeStart = Date.now();

      this.setRequestInitialState();

      this.updateRequestState({
        hasProcessedDocs: false,
        answerText: isArabic
          ? '🔎 البحث بعمق للعثور على أفضل التطابقات لسؤالك...'
          : this.translate.instant('query.diggingDeep'),
        userQuery,
        runtimeInMillis: undefined
      });
      this.updateAnswer();

      const response = await this.fetchData(
        userQuery,
        querySettings,
        queryFilters
      );

      if (!response.ok) {
        const err = this.translate.instant('query.errorServerResponse');
        throw new Error(`${err} ${response.status}`);
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      const delay = 10; // Initialize to 10ms for all characters

      let dataBuffer = '';
      let tempBuffer = ''; // Temporary buffer to hold characters after '['
      let isCheckingForTag = false; // Flag to indicate if we are checking for '[NO_ANSWER_FOUND]'

      // eslint-disable-next-line no-constant-condition
      while (true) {
        if (this.stopStreaming$.getValue()) {
          this.stopStreaming$.next(false);
          this.clearAnswerPartially();
          break;
        }

        if (reader) {
          const { value, done } = await reader.read();

          if (done) {
            this.updateAnswer();
            this.stopStreaming$.next(true);
            //this.requestInProgress$.next(false);
            //this.requestState.requestInProgress = false;
            this.historyUpdated$.next(this.answerSubject.getValue());
            break;
          }

          let chunk = decoder.decode(value, { stream: true });
          chunk = chunk.replace(/data: {2}-/g, '    *').replace(/data: /g, '');

          dataBuffer += chunk;

          if (
            !this.requestState.hasProcessedDocs &&
            dataBuffer.includes('__DOCS_END__')
          ) {
            dataBuffer = this.getTextFromFoundFiles(dataBuffer, isArabic);
          }

          if (this.requestState.hasProcessedDocs) {
            const streamStartIndex = dataBuffer.indexOf('__STREAM_START__');
            if (streamStartIndex !== -1) {
              this.requestState.answerText = '';
              this.updateAnswer();
              dataBuffer = dataBuffer.substring(
                streamStartIndex + '__STREAM_START__'.length
              );
            }

            dataBuffer = this.replaceNoneFoundTags(dataBuffer);

            for (const char of dataBuffer) {
              if (this.stopStreaming$.getValue()) break;

              if (isCheckingForTag) {
                tempBuffer += char;

                if (tempBuffer === 'NO_ANSWER_FOUND]') {
                  //console.log('[NO_ANSWER_FOUND] found. Removing it.');
                  tempBuffer = '';
                  isCheckingForTag = false;
                } else if (!'[NO_ANSWER_FOUND]'.startsWith(tempBuffer)) {
                  // If the temporary buffer doesn't match the beginning of the tag, append it to the answer and reset
                  this.requestState.answerText += '[' + tempBuffer;
                  this.updateAnswer();
                  tempBuffer = '';
                  isCheckingForTag = false;
                }
              } else {
                // Found '['. Starting to check for '[NO_ANSWER_FOUND]
                if (char === '[') {
                  tempBuffer = '';
                  isCheckingForTag = true;
                } else if (!this.stopStreaming$.getValue()) {
                  await this.processChar(char, delay);
                }
              }
            }

            dataBuffer = '';
          }
        }
      }
    } catch (error: any) {
      console.error(`Error in handleStreamedData(): ${error.message}`);
      if (
        error.message === 'The user aborted a request.' ||
        error.message === 'BodyStreamBuffer was aborted'
      ) {
        this.updateRequestState({
          error_code: `${error.message}`,
          answerText: isArabic
            ? '🔎 البحث بعمق للعثور على أفضل التطابقات لسؤالك...' /* TODO: this solution should be replaced once we have a arabic language file too */
            : this.translate.instant('query.diggingDeep')
        });
      } else {
        const err = this.translate.instant('query.errorFetchingData');
        this.updateRequestState({
          error_code: `${err}:: ${error.message}`,
          answerText: `${err}: ${error.message}`
        });
      }

      this.updateAnswer();
      this.stopStreaming$.next(true);
    } finally {
      this.requestState.requestInProgress = false;
    }
  }

  updateRequestState(changes: Partial<RequestState>): void {
    this.requestState = { ...this.requestState, ...changes };
  }

  updateAnswer() {
    const answer: Answer = {
      answer: this.requestState.answerText,
      answers: this.requestState.documents,
      sources: this.createSources(this.requestState.documents) ?? [],
      runtimeInMillis: this.requestState.runtimeInMillis,
      index: this.requestState.index,
      error_code: this.requestState.error_code,
      prompt: this.requestState.prompt,
      userQuery: this.requestState.userQuery
    };
    this.answerSubject.next({ ...answer });
  }

  clearAnswerPartially() {
    this.answerSubject.next({
      answer: this.requestState.answerText,
      answers: this.requestState.documents,
      runtimeInMillis: undefined,
      index: this.requestState.index,
      error_code: this.requestState.error_code,
      prompt: this.requestState.prompt,
      userQuery: this.requestState.userQuery,
      sources: []
    });
  }

  makeQuery(
    userQuery: string,
    querySettings: Partial<QuerySettings>,
    queryFilters?: Partial<QueryFilter>
  ): Observable<Answer> {
    // TODO Don't like this if here. Think better solution
    // if (querySettings.model === ModelType.BART) {
    //   return this.makeOpenSourceLLMQuery(userQuery);
    // }

    queryFilters = this.checkQueryFilters(queryFilters);

    const body: QueryBody = {
      index: querySettings?.index,
      model: querySettings?.model,
      answer_type: querySettings?.answerType,
      user_query: userQuery,
      user_id: '', // TODO: check what is needed?
      retriever_number: querySettings?.retriever_number,
      generator_number: 1,
      temperature: querySettings?.temperature,
      second_index: querySettings?.second_index ?? undefined,
      filters: queryFilters ?? undefined
    };

    return this.httpClient.post<Answer>(
      `${environment.haystackBackendUrl}/get_answer`,
      body
    );
  }

  checkQueryFilters(
    queryFilters: Partial<QueryFilter> | undefined
  ): Partial<QueryFilter> | undefined {
    if (!queryFilters) {
      return undefined;
    }

    const filters = { ...queryFilters };

    if (!filters?.sector?.length) {
      delete filters?.sector;
    }
    if (!filters?.industry_type?.length) {
      delete filters?.industry_type;
    }

    if (!filters?.service_offering?.length) {
      delete filters?.service_offering;
    }

    return filters;
  }

  makeOpenSourceLLMQuery(userQuery: string) {
    return this.httpClient
      .get<OpenSourceLLMAnswer>(
        `${environment.openSourceLLMBackendUrl}/bart/${userQuery}`
      )
      .pipe(
        map(response => {
          return {
            index: 'Bart',
            answer: response.answer,
            answers: [],
            sources: [],
            prompt: null,
            error_code: null
          };
        })
      );
  }
}
