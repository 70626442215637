<div class="wrapper">
  <span
    class="fi fi-{{ currentLang }} clickable"
    [matMenuTriggerFor]="menu"></span>
  <mat-menu #menu="matMenu">
    <button
      *ngFor="let lang of languages"
      (click)="switchLanguage(lang.id)"
      mat-menu-item>
      <span class="fi fi-{{ lang.id }} flag"></span>
      {{ lang.name }}
    </button>
  </mat-menu>
</div>
