<div class="history-wrapper scrollbar">
  <h2 class="sticky-h2">{{ 'history.title' | translate }}</h2>
  <div *ngIf="(history$ | async)?.length; else noHistory" class="scrollbar">
    <button
      class="history"
      *ngFor="let history of history$ | async"
      (click)="selectAnswer(history)">
      <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">
        format_quote
      </mat-icon>
      <div class="history-text">{{ history.query }}</div>
    </button>
  </div>
</div>

<ng-template #noHistory>
  <div>{{ 'history.noQueries' | translate }}</div>
</ng-template>

<!-- <pre>{{ (history$ | async).map(x => x.answer.answer)) | json }}</pre> -->
