import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';
import { FeedbackDialogComponent } from '../../../shared/ui/feedback-dialog/feedback-dialog.component';
import { LangSwitcherComponent } from '../../../shared/ui/lang-switcher/lang-switcher.component';
import { StateService } from '../../data-access/state.service';
import { AuthOperation, Configuration } from '../../model/configurations.model';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    TranslateModule,
    LangSwitcherComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() isLogin: boolean;
  @Input() activeAccountName: string | undefined;
  @Output() authChange = new EventEmitter();

  title$ = this.stateService.selectedConfiguration$.pipe(
    map(({ title }) => title)
  );
  logoPath$ = this.stateService.selectedConfiguration$.pipe(
    map(({ logoFileName }) => `../assets/logos/${logoFileName}`)
  );
  configurations$ = this.stateService.configurations$;
  isTabletLandscapeUp$ = this.stateService.isTabletLandscapeUp$;

  requestFeature = 'Request Feature';
  reportBug = 'Report a bug';

  AuthOperation = AuthOperation;

  constructor(
    private stateService: StateService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.translate
      .get('feedback.requestFeature')
      .pipe(takeUntilDestroyed())
      .subscribe(translation => (this.requestFeature = translation));
    this.translate
      .get('feedback.reportBug')
      .pipe(takeUntilDestroyed())
      .subscribe(translation => (this.reportBug = translation));
  }

  updateConfiguration(configuration: Configuration) {
    this.stateService.updateSelectedConfiguration(configuration);
  }

  openDialog(feedbackSubject: string): void {
    this.dialog.open(FeedbackDialogComponent, {
      data: {
        feedbackSubject: feedbackSubject
      },
      width: '50%'
    });
  }

  changeAuth(operation: AuthOperation) {
    this.authChange.emit({ operation });
  }
}
