import { ClientName } from 'src/app/shared/model/query-body.model';
import {
  AnswerType,
  Configuration,
  SearchIndex
} from '../configurations.model';

export const MUKILTEO_COUNTY_INDEX: Configuration = {
  title: ClientName.MUKILTEO_COUNTY_INDEX,
  shortName: ClientName.MUKILTEO_COUNTY_INDEX,
  logoFileName: 'mukilteo.png',
  searchSettings: {
    index: SearchIndex.MUKILTEO_COUNTY_INDEX,
    answerType: AnswerType.LFGA,
    retriever_number: 6,
    temperature: 0,
    document_chat_index: SearchIndex.DOCUMENT_CHAT_INDEX
  },
  examples: [
    {
      language: 'english',
      flagClass: 'us',
      questions: [
        'How do I setup trash and recycling collection?',
        'How can I pay my electric bill?',
        'Where can I find information about the public school district?',
        'How can I setup water service?',
        'Where do I pay a parking ticket?'
      ]
    }
  ]
};
