import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-environment-notification',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './environment-notification.component.html',
  styleUrls: ['./environment-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnvironmentNotificationComponent {
  protected readonly environment = environment;
}
