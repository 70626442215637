import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StateService } from 'src/app/core/data-access/state.service';

@Component({
  selector: 'app-slider',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    FormsModule,
    MatSliderModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule
  ],
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent {
  private stateService = inject(StateService);

  value = 6;

  updateTopKValue($event: number) {
    this.stateService.updateTopKValue($event);
  }
}
