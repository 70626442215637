<label for="slider">
  <span>{{ 'settings.sliderLabel' | translate }}: </span>
  <div>
    <span>{{ slider.value }}</span>
    <mat-icon
      matTooltip="{{ 'settings.sliderTooltip' | translate }}"
      class="tooltip-icon"
      inline>
      info_outlined
    </mat-icon>
  </div>
</label>
<mat-slider min="3" max="15" step="1" showTickMarks discrete>
  <input
    matSliderThumb
    [(ngModel)]="value"
    #slider
    (valueChange)="updateTopKValue($event)" />
</mat-slider>
