import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatMessage } from '../../../features/chatbot/model/chat-message.model';
import { ChatSourcesComponent } from '../chat-sources/chat-sources.component';
import { SourcesComponent } from '../answer/sources/sources.component';
import { StateService } from 'src/app/core/data-access/state.service';
import { MarkdownModule } from 'ngx-markdown';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ClientName } from '../../model/query-body.model';

@Component({
  selector: 'app-chat-message',
  standalone: true,
  imports: [
    CommonModule,
    ChatSourcesComponent,
    SourcesComponent,
    MarkdownModule
  ],
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent {
  @Input() chatMessage!: ChatMessage;

  public stateService = inject(StateService);
  private sanitizer = inject(DomSanitizer);

  isShowSources = false;
  ClientName = ClientName;

  get sanitizedAnswer(): SafeHtml {
    return this.chatMessage.botMessage.bot &&
      this.chatMessage.botMessage.bot.answer
      ? this.sanitizer.bypassSecurityTrustHtml(
          this.chatMessage.botMessage.bot.answer
        )
      : '';
  }

  toggleSources() {
    this.isShowSources = !this.isShowSources;
  }
}
