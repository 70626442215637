<ng-container *ngIf="answer.answers?.length">
  <mat-divider class="divider"></mat-divider>
  <h2>Sources:</h2>
  <ng-container *ngFor="let source of extractSources(answer); let i = index">
    <div class="table-view">
      <h3>
        {{ i + 1 }}.
        <a *ngIf="source.name" href="{{ source.url }}" target="_blank">
          {{ removeHTTPS(source.name) }}
        </a>

        (score={{ source.score }})
      </h3>

      <i
        class="fa fa-eye"
        (click)="preview($event, i)"
        (keyup.enter)="preview($event, i)"
        title="Preview"
        *ngIf="hideAllContents"
        tabindex="0"></i>
    </div>

    <!-- Only show this paragraph if i equals hoveredIndex when hideAllContents is true -->
    <p *ngIf="!hideAllContents || (hideAllContents && i === hoveredIndex)">
      <b>Content:</b> {{ source.content }}
    </p>
  </ng-container>
</ng-container>
