import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { StateService } from './core/data-access/state.service';
import { Subject, filter, takeUntil } from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus
} from '@azure/msal-browser';
import { AccessTokenService } from './core/data-access/access-token.service';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isTabletLandscapeUp$ = this.stateService.isTabletLandscapeUp$;
  isSideNavOpen$ = this.stateService.isSideNavOpen$;

  isActiveAccount = false;
  activeAccountName: string | undefined = undefined;

  private readonly _destroying$ = new Subject<void>();
  protected readonly environment = environment;

  constructor(
    private authService: MsalService,
    private accessTokenService: AccessTokenService,
    private msalBroadcastService: MsalBroadcastService,
    private stateService: StateService,
    private router: Router,
    translate: TranslateService
  ) {
    translate.setDefaultLang('us');
    translate.use('us');
    translate.addLangs(['de']);
  }

  ngOnInit() {
    this.setMSALConfigs();

    if (environment.production) {
      this.setUpAnalytics();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  setMSALConfigs(): void {
    this.setIsActiveAccount();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setIsActiveAccount();
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.accessTokenService.accessToken$.next(payload.idToken);
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((request: any) => {
        this.setIsActiveAccount();
        this.checkAndSetActiveAccount();

        if (this.isActiveAccount) {
          this.storeAccessToken(request);
        } else {
          this.login();
        }
      });
  }

  setIsActiveAccount() {
    this.isActiveAccount =
      this.authService.instance.getAllAccounts().length > 0;
    if (this.authService.instance.getAllAccounts().length === 1) {
      this.activeAccountName =
        this.authService.instance.getActiveAccount()?.name;
    }
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.authService.loginRedirect();
      });
  }

  logout() {
    this.authService.logoutRedirect();
  }

  onAuthEvent($event: any) {
    if ($event.operation === 'login') {
      this.login();
    } else if ($event.operation === 'logout') {
      this.logout();
    }
  }

  async storeAccessToken(request: any): Promise<void> {
    const msalInstance = this.authService.instance;
    const tokenRequest = {
      account: msalInstance.getActiveAccount() || null,
      ...request
    };
    let tokenResponse;

    try {
      tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
    } catch (error) {
      this.msalBroadcastService.inProgress$
        .pipe(takeUntil(this._destroying$))
        .subscribe((status: InteractionStatus) => {
          if (status !== InteractionStatus.None) {
            throw new Error('interaction_in_progress');
          } else {
            tokenResponse = msalInstance.acquireTokenSilent(tokenRequest);
          }
        });
      console.log(error);
    }

    if (tokenResponse?.idToken) {
      this.accessTokenService.accessToken$.next(tokenResponse.idToken);
      console.log('Successfully set accessToken');
    }
  }

  toggleSideNav(isOpen: boolean): void {
    this.stateService.toggleSideNav(isOpen);
  }

  setUpAnalytics() {
    this.router.events.pipe(takeUntil(this._destroying$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-1H9Z08F5N3', { page_path: event.urlAfterRedirects });
      }
    });
  }
}
