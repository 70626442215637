import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Answer, RequestState } from '../../../shared/model/answer.model';
import {
  ChatHistory,
  QueryBody,
  QuerySettings
} from 'src/app/shared/model/query-body.model';
import { TranslateService } from '@ngx-translate/core';
import { ChatMessage } from '../model/chat-message.model';
import { AccessTokenService } from 'src/app/core/data-access/access-token.service';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  private accessTokenService = inject(AccessTokenService);
  private translate = inject(TranslateService);

  exampleQuestion = this.translate.instant('example.exampleQuestion');
  welcomeMessage$ = this.translate.instant('example.welcomeMessage');

  answerSubject = new BehaviorSubject<Answer>(new Answer());
  answer$ = this.answerSubject.asObservable();

  chatHistorySubject = new BehaviorSubject<ChatMessage[]>([
    {
      userMessage: {
        user: '',
        userTimestamp: null
      },
      botMessage: {
        bot: {
          answer:
            localStorage.getItem('lang') === 'de'
              ? 'Willkommen! Ich bin ein KI-Assistent. Wie kann ich dir heute helfen?'
              : 'Welcome! I am an AI assistant. How can I assist you today?',
          answers: [],
          sources: [],
          runtimeInMillis: 0,
          index: '',
          error_code: null,
          prompt: null,
          userQuery: ''
        },
        botTimestamp: new Date().toLocaleString()
      }
    }
  ]);
  chat$ = this.chatHistorySubject.asObservable();
  chatUpdated$ = new BehaviorSubject<ChatMessage[]>([]);
  chatCounter = 0;

  requestState = this.setRequestInitialState();
  requestInProgress$ = new BehaviorSubject<boolean>(false);

  setRequestInitialState(): RequestState {
    return {
      userQuery: '',
      documents: [],
      sources: [],
      stream: true,
      answerText: '',
      requestInProgress: true,
      hasProcessedDocs: false,
      error_code: null,
      runtimeInMillis: 0,
      index: '',
      prompt: null
    };
  }

  async sendChatMessage(
    message: string,
    chat_history: ChatHistory[],
    chatQuerySettings: Partial<QuerySettings>,
    filterByFiles?: string[],
    filterByTaskId?: string[]
  ): Promise<Response> {
    if (
      (filterByTaskId && filterByTaskId.length === 0) ||
      filterByFiles === undefined
    ) {
      filterByTaskId = undefined;
    }

    const url = environment.haystackBackendUrl + '/chat';
    const body: QueryBody = {
      answer_type: chatQuerySettings.answerType,
      chat_history: [...chat_history, { user: message, bot: '' }],
      filters: { task_id: filterByTaskId, name: filterByFiles },
      generator_number: 1,
      hybrid_search: chatQuerySettings.isHybridSearch ?? undefined,
      index: chatQuerySettings.index,
      model: chatQuerySettings.model,
      retriever_number: chatQuerySettings.retriever_number,
      stream: chatQuerySettings.stream ?? true,
      summarization: chatQuerySettings.isSummarization,
      temperature: chatQuerySettings.temperature,
      user_id: 'Angular_frontend',
      user_query: chatQuerySettings.user_query || ''
    };

    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.accessTokenService.accessToken$.value
      },
      body: JSON.stringify(body)
    });
  }

  async handleChat(
    message: string,
    chat_history: ChatHistory[],
    chatQuerySettings: Partial<QuerySettings>,
    filterByFiles?: string[],
    filterByTaskId?: string[]
  ): Promise<any> {
    try {
      this.requestState = this.setRequestInitialState();
      // debugger;
      const chat = this.chatHistorySubject.getValue();
      chat.push(new ChatMessage());
      this.chatHistorySubject.next([...chat]);
      this.requestState.hasProcessedDocs = false;
      this.chatCounter = chat.length - 1;
      this.requestInProgress$.next(true);
      this.requestState.userQuery = message;
      const userQueryTimestamp = new Date().toLocaleString();
      this.updateChat(userQueryTimestamp, undefined);
      const response = await this.sendChatMessage(
        message,
        chat_history,
        chatQuerySettings,
        filterByFiles,
        filterByTaskId
      );

      if (!response.ok) {
        const err = this.translate.instant('query.errorServerResponse');
        throw new Error(`${err} ${response.status}`);
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let dataBuffer = '';
      const start = Date();
      let streamStarted = false; // flag to indicate if stream has started
      let tempBuffer = ''; // Temporary buffer to hold characters after '['
      let isCheckingForTag = false; // Flag to indicate if we are checking for '[NO_ANSWER_FOUND]'

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader!.read();
        if (done) {
          const end = Date();
          const runtimeInMillis = Date.parse(end) - Date.parse(start);
          this.requestState.runtimeInMillis = runtimeInMillis;
          this.updateChat(userQueryTimestamp, new Date().toLocaleString());
          const chatMessages = this.chatHistorySubject.getValue();
          this.chatUpdated$.next(chatMessages);
          this.updateChatHistory(chatMessages);
          break;
        }

        let chunk = decoder.decode(value, { stream: true });

        if (chunk.includes('data: ')) {
          chunk = chunk.replace(/data: /g, '');
        }
        dataBuffer += chunk;
        if (
          !this.requestState.hasProcessedDocs &&
          dataBuffer.includes('__DOCS_END__')
        ) {
          const [docsString, remainingText] = dataBuffer.split('__DOCS_END__');
          const docs = JSON.parse(docsString.replace(/^data: /, ''));
          this.requestState.documents = docs;
          dataBuffer = remainingText;
          this.requestState.hasProcessedDocs = true;
          this.updateChat(userQueryTimestamp);
        }

        const streamStartIndex = dataBuffer.indexOf('__STREAM_START__');
        if (streamStartIndex !== -1) {
          streamStarted = true;
          this.requestState.answerText = '';
          this.updateChat(userQueryTimestamp);
          dataBuffer = dataBuffer.substring(
            streamStartIndex + '__STREAM_START__'.length
          );
        }

        const processChar = async (char: string, delay: number) => {
          return new Promise<void>((resolve, reject) => {
            try {
              setTimeout(() => {
                this.requestState.answerText += char;
                this.updateChat(userQueryTimestamp);
                resolve();
              }, delay);
            } catch (error) {
              reject(error);
            }
          });
        };

        if (streamStarted) {
          const cleanedBuffer = dataBuffer.replace(/data: /g, '');

          const delay = 0; // Initialize to 1ms for all characters
          for (const char of cleanedBuffer) {
            if (isCheckingForTag) {
              tempBuffer += char;

              if (tempBuffer === '[NO_ANSWER_FOUND]') {
                console.log('[NO_ANSWER_FOUND] found. Removing it.');
                tempBuffer = '';
                isCheckingForTag = false;
              } else if (!'[NO_ANSWER_FOUND]'.startsWith(tempBuffer)) {
                await processChar('[' + tempBuffer, delay);
                tempBuffer = '';
                isCheckingForTag = false;
              }
            } else {
              if (char === '[') {
                console.log(
                  "Found '['. Starting to check for '[NO_ANSWER_FOUND]'"
                );
                isCheckingForTag = true;
                tempBuffer = '['; // Initialize tempBuffer with the starting '['
              } else {
                await processChar(char, delay);
              }
            }
          }
          dataBuffer = '';
        }
      }
    } catch (error: any) {
      const err = this.translate.instant('query.errorFetchingData');
      console.error(`${err}: ${error.message}`);
      this.requestState.error_code = `${err}:: ${error.message}`;
    } finally {
      this.requestState.requestInProgress = false;
      this.requestInProgress$.next(false);
    }
  }

  updateChat(userTimestamp?: string, botTimestamp?: string): void {
    const chatHistory: ChatMessage[] = this.chatHistorySubject.getValue();

    const answer: Answer = {
      answer: this.requestState.answerText,
      answers: this.requestState.documents,
      sources: this.requestState.sources,
      runtimeInMillis: this.requestState.runtimeInMillis,
      index: this.requestState.index,
      error_code: this.requestState.error_code,
      prompt: this.requestState.prompt,
      userQuery: this.requestState.userQuery
    };

    const chatMessage: ChatMessage = {
      userMessage: {
        user: this.requestState.userQuery,
        userTimestamp:
          this.requestState.userQuery && userTimestamp ? userTimestamp : null
      },
      botMessage: {
        bot: answer,
        botTimestamp:
          this.requestState.answerText && botTimestamp ? botTimestamp : null
      }
    };

    chatHistory[this.chatCounter] = chatMessage;
    this.chatHistorySubject.next([...chatHistory]);
  }

  updateChatHistory(val: ChatMessage[]): void {
    this.chatHistorySubject.next(val);
  }

  // Not used anywhere at the moment:
  /* sendMessage(
    message: string,
    chat_history: ChatHistory[],
    chatQuerySettings: Partial<QuerySettings>
  ): Observable<Answer> {
    const body: QueryBody = {
      summarization: chatQuerySettings.isSummarization,
      chat_history: [...chat_history, { user: message, bot: '' }],
      index: chatQuerySettings.index,
      model: chatQuerySettings.model,
      answer_type: chatQuerySettings.answerType,
      user_id: 'Angular_frontend',
      retriever_number: chatQuerySettings.retriever_number,
      generator_number: 1,
      temperature: chatQuerySettings.temperature,
      user_query: chatQuerySettings.user_query || ''
    };

    return this.httpClient.post<Answer>(
      `${environment.haystackBackendUrl}/chat`,
      body
    );
  } */
}
