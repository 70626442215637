import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, filter, forkJoin, map, switchMap, take } from 'rxjs';
import { ReleaseNote } from '../model/release-notes.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AccessTokenService } from 'src/app/core/data-access/access-token.service';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNoteService {
  private accessTokenService = inject(AccessTokenService);
  private httpClient = inject(HttpClient);

  releaseNotes$ = new BehaviorSubject<ReleaseNote[] | undefined>(undefined);

  getNotes() {
    return this.accessTokenService.accessToken$.pipe(
      filter(token => token.length > 0),
      switchMap(accessToken => {
        const params = {
          latest: false
        };

        const headers = new HttpHeaders();
        headers.set('Authorization', 'Bearer ' + accessToken);

        const haystackNotes = this.httpClient
          .get<{ release_notes: ReleaseNote[] }>(
            `${environment.haystackBackendUrl}/release_notes`,
            {
              headers: { Authorization: 'Bearer ' + accessToken },
              params
            }
          )
          .pipe(map(response => response.release_notes));

        const ingestionNotes = this.httpClient
          .get<{ release_notes: ReleaseNote[] }>(
            `${environment.ingestionBackendUrl}/release_notes`,
            {
              headers: { Authorization: 'Bearer ' + accessToken },
              params
            }
          )
          .pipe(map(response => response.release_notes));

        return forkJoin([haystackNotes, ingestionNotes]).pipe(
          map(results => {
            const combinedResults = [...results[0], ...results[1]];

            return combinedResults.sort((a, b) => {
              return new Date(b.date).getTime() - new Date(a.date).getTime();
            });
          })
        );
      })
    );
  }

  handleReleaseNotes() {
    this.getNotes()
      .pipe(take(1))
      .subscribe({
        next: (response: ReleaseNote[]) => {
          this.releaseNotes$.next(response);
        },
        error: error => {
          console.error('Error handling release notes:', error);
        }
      });
  }
}
