import { ModelType } from 'src/app/shared/model/query-body.model';

export interface Configuration {
  title: string;
  shortName: string;
  logoFileName: string;
  searchSettings: {
    index: SearchIndex;
    answerType: AnswerType;
    retriever_number: number;
    temperature: number;
    second_index?: string;
    hybrid_search?: boolean;
    document_chat_index?: SearchIndex;
  };
  examples: ExampleQuestion[];
}

export enum SearchIndex {
  DOCUMENT_CHAT_INDEX = 'poc_documentchat',
  NORTAL_HR_INDEX = 'hr_index',
  NORTAL_MARKETING_INDEX = 'project_bank_index',
  PB_MARKETING_INDEX = 'poc_pb_marketing',
  PROPOSAL_GENERATOR_INDEX = 'proposal_generator_rfps',
  SHAREPOINT_INDEX = 'poc_sharepoint_cosine_v1',
  JOHNSTONE_SUPPLY = 'poc_johnstone_supply',
  CALDWELL_COUNTY_INDEX = 'poc_caldwell',
  BOSCH_HEALTH_INDEX = 'poc_bosch_health',
  MUKILTEO_COUNTY_INDEX = 'poc_mukilteo'
}

export enum AnswerType {
  LFGA = 'lfqa',
  LFGA_GOOGLE = 'lfqa_google',
  LFGA_AGENT = 'lfqa_agent'
}

export interface ExampleQuestion {
  flagClass: string; // 2-digit value from here https://flagicons.lipis.dev/
  language: string;
  questions: string[];
}

export const CONFIGURATION_NAME = 'configurationName';
export const CHAT_HISTORY = 'chatHistory';

export interface NameValue {
  name: string;
  value: string | number | ModelType;
}

export enum AuthOperation {
  LOGIN = 'login',
  LOGOUT = 'logout'
}
