import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  of,
  switchMap,
  take,
  tap,
  throwError
} from 'rxjs';

interface AuthMeModel {
  access_token: string;
  expires_on?: Date;
  id_token?: string;
  provider_name?: string;
  refresh_token?: string;
  user_claims?: UserClaim[];
  user_id?: string;
}

interface UserClaim {
  typ?: string;
  val?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private http = inject(HttpClient);

  accessToken$ = new BehaviorSubject<string>('');

  getAccessToken() {
    return this.http.get<AuthMeModel[]>('/.auth/me', {
      observe: 'response'
    });
  }

  refreshAccessToken() {
    return this.http
      .get<Response>('/.auth/refresh', { observe: 'response' })
      .pipe(
        tap(() => console.log('accessToken refreshed')),
        catchError(error => {
          console.error('Error refreshing accessToken', error);
          return throwError(() => new Error('Failed to refresh token'));
        })
      );
  }

  isExpired(expiresOn: string): boolean {
    const expirationDate = new Date(expiresOn);
    const currentDate = new Date();
    return expirationDate < currentDate;
  }

  handleAccessToken() {
    this.getAccessToken()
      .pipe(
        switchMap((response: any) => {
          if (this.isExpired(response.body[0].expires_on)) {
            return this.refreshAccessToken().pipe(
              switchMap(() => this.getAccessToken())
            );
          } else {
            return of(response);
          }
        }),
        take(1)
      )
      .subscribe({
        next: (response: any) => {
          const responseBody = response.body[0];
          this.accessToken$.next(responseBody.access_token);
        },
        error: error => {
          console.error('Error handling accessToken:', error);
        }
      });
  }
}
