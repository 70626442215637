<div class="message-container">
  <div class="user-message" *ngIf="chatMessage?.userMessage?.user">
    <div class="message-content-container">
      <div
        class="message-header"
        *ngIf="
          (stateService.selectedConfiguration$ | async)?.shortName as shortName
        ">
        <span class="user-icon">{{ shortName[0] }}</span>
        <p>{{ shortName }}</p>
        <span class="message-time">{{
          chatMessage.userMessage.userTimestamp
        }}</span>
      </div>
      <div class="message-content">
        <p class="message-text">{{ chatMessage.userMessage.user }}</p>
      </div>
    </div>
  </div>
  <div class="bot-message" *ngIf="chatMessage?.botMessage?.bot">
    <div class="message-content-container">
      <div class="message-header">
        <img [src]="'../assets/logos/chat_message_tark_logo.svg'" alt="Logo" />
        <p>{{ ClientName.NORTAL_TARK }}</p>
        <span class="message-time">
          {{ chatMessage.botMessage.botTimestamp }}
        </span>
      </div>
      <div class="message-content">
        <markdown
          *ngIf="chatMessage?.botMessage?.bot?.answer?.length"
          class="message-text"
          [data]="chatMessage.botMessage.bot?.answer">
        </markdown>

        <div *ngIf="!chatMessage?.botMessage?.bot?.answer?.length">
          <img src="assets/images/dots.gif" height="20px" alt="waiting ..." />
        </div>

        <button
          *ngIf="chatMessage.botMessage.bot?.answers?.length"
          class="detail-button"
          (click)="toggleSources()">
          {{ isShowSources ? 'Hide sources' : 'Show sources' }}
        </button>
        <app-sources
          *ngIf="chatMessage && isShowSources"
          [answer]="chatMessage.botMessage.bot!"
          [hideAllContents]="false">
        </app-sources>
      </div>
    </div>
  </div>
</div>
