import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule
} from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StateService } from '../../../core/data-access/state.service';
import { environment } from 'src/environments/environment';
import {
  AnswerType,
  SearchIndex
} from 'src/app/core/model/configurations.model';
import { ModelType } from 'src/app/shared/model/query-body.model';
import { SliderComponent } from '../ui/slider/slider.component';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    AsyncPipe,
    CommonModule,
    MatRadioModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    SliderComponent
  ],
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent {
  private stateService = inject(StateService);
  private translate = inject(TranslateService);

  selectedConfiguration = this.stateService.selectedConfiguration$;
  selectedModel$ = this.stateService.selectedModel$;
  selectedModel = this.selectedModel$.value;
  selectedAnswerType$ = this.stateService.selectedAnswerType$;
  selectedAnswerType = this.selectedAnswerType$.value;
  environment = environment;
  searchIndex = SearchIndex;
  selectedIndex = '';
  selectedIndex$ = this.stateService.selectedIndex$;
  ModelType = ModelType;

  selectModel(event$: MatSelectChange) {
    this.selectedModel = event$.value;
    this.stateService.updateSelectedModel(event$.value);
  }

  selectIndex(event$: MatSelectChange) {
    this.selectedIndex = event$.value ? event$.value : undefined;
    this.stateService.updateSelectedIndex(event$.value);
  }

  isUseWebSearch(selectedAnswerType: AnswerType | null) {
    return selectedAnswerType === AnswerType.LFGA_GOOGLE;
  }

  selectAnswerType($event: MatCheckboxChange): void {
    this.selectedAnswerType = $event.checked
      ? AnswerType.LFGA_GOOGLE
      : AnswerType.LFGA;
    this.stateService.updateSelectedAnswerType(this.selectedAnswerType);
  }

  updateIsSummarization($event: MatCheckboxChange): void {
    this.stateService.updateIsSummarization($event.checked);
  }

  updateIsHybridSearch($event: MatCheckboxChange): void {
    this.stateService.updateIsHybridSearch($event.checked);
  }

  updateIsUseSecondIndex($event: MatCheckboxChange): void {
    this.stateService.updateIsUseSecondIndex($event.checked);
  }

  trackByIndex(index: number): number {
    return index;
  }
}
