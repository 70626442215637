import { Environment } from './environment.model';

export const environment: Environment = {
  name: 'aks',
  production: false,
  msalConfig: {
    auth: {
      clientId: '39d0b89e-acdf-4a1d-8dc9-b73aae8cd409',
      authority:
        'https://login.microsoftonline.com/7c359f6b-9f2f-4042-bf2d-b08ce16c5cc1'
    }
  },
  apiConfig: {
    scopes: ['user.read', 'profile', 'openid', 'email'],
    uri: 'https://graph.microsoft.com/v1.0/me'
  },
  // local BE
  haystackBackendUrl:
    'https://haystack-backend-demo.eastus2.cloudapp.azure.com',

  // dev BE
  //haystackBackendUrl: 'http://haystack-backend-dev.azurewebsites.net',

  // test BE
  // haystackBackendUrl: 'https://haystack-backend-test.azurewebsites.net',

  // ingestionBackendUrl: 'https://ingestion-backend.azurewebsites.net',
  ingestionBackendUrl:
    'https://ingestion-backend-demo.eastus2.cloudapp.azure.com',
  openSourceLLMBackendUrl: 'https://opensource-backend.azurewebsites.net',
  azureLogicAppUrl:
    'https://prod-13.westeurope.logic.azure.com:443/workflows/09acf2cd29fe46a3a401ade15fadd461/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ShbCowgWTK0LJmh5kG96YF8-n9iGgIxWRpFbX4lSgSg'
};
