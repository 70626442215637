import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { Answer, Document } from '../../../model/answer.model';
import { Source } from '../../../model/sources.model';

@Component({
  selector: 'app-sources',
  standalone: true,
  imports: [CommonModule, MatDividerModule],
  templateUrl: './sources.component.html',
  styleUrls: ['./sources.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SourcesComponent {
  @Input({ required: true }) answer: Answer;
  @Input() hideAllContents?: boolean;
  hoveredIndex = 0;

  removeHTTPS(link: string) {
    return link.replace('https://', '');
  }

  extractSources(answer: Answer): Source[] {
    /*
     * When info is not found in internal knowledge base and answer_type parameter
     * is lfqa_google, then the LLM will google the answer.
     * In those case, the response body is a bit different.
     * Why? Reason unknown
     * Answer: The reason is that web search uses a different type of node that returns a response in a different format.
     */
    // TODO talk with BE to refactor its logic to give back answers with same format
    if (answer.answers[0].documents) {
      return answer.answers[0].documents.map(document =>
        this.mapSource(document)
      );
    }
    return answer?.answers?.map(document => this.mapSource(document));
  }

  preview(event: MouseEvent | Event, index: number) {
    this.hoveredIndex = index;
  }

  private mapSource(document: Document) {
    const source: Source = {
      name: document.meta.name ?? document.meta.url ?? document.meta.link,
      url: document.meta.url ?? document.meta.link,
      score: document.score ?? document.meta.score,
      content: document.content
    };
    return source;
  }
}
