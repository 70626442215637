<h3>{{ 'settings.resultSettings' | translate }}</h3>
<mat-form-field class="select-field" appearance="outline">
  <!-- Open Angular issue on "Incorrect use of <label for=FORM_ELEMENT>" https://github.com/angular/components/issues/27241 -->
  <mat-label>{{ 'settings.model' | translate }}</mat-label>
  <mat-select
    (selectionChange)="selectModel($event)"
    [(ngModel)]="selectedModel">
    <mat-option [value]="ModelType.GPT3_Turbo"
      >OpenAI GPT-3.5 Turbo
    </mat-option>

    <mat-option [value]="ModelType.GPT3_Turbo_16k"
      >OpenAI GPT-3.5 Turbo 16k</mat-option
    >

    <mat-option [value]="ModelType.GPT4">OpenAI GPT-4</mat-option>
    <mat-option [value]="ModelType.GPT4_Turbo">OpenAI GPT-4 Turbo</mat-option>


    <!--    <mat-option-->
    <!--      [value]="ModelType.BART"-->
    <!--      *ngIf="environment.name !== 'production'"-->
    <!--      >Bart</mat-option-->
    <!--    >-->
  </mat-select>
</mat-form-field>

<p>
  <mat-checkbox
    (change)="selectAnswerType($event)"
    [checked]="isUseWebSearch(selectedAnswerType$ | async)">
    <span class="checkbox-label">
      {{ 'settings.webSearch' | translate }}
      <mat-icon
        matTooltip="{{ 'settings.webSearchTooltip' | translate }}"
        class="tooltip-icon"
        inline>
        info_outlined
      </mat-icon>
    </span>
  </mat-checkbox>
</p>

<p *ngIf="environment.name !== 'production'">
  <mat-checkbox (change)="updateIsSummarization($event)">
    <span class="checkbox-label">
      {{ 'settings.summarization' | translate }}
      <mat-icon
        matTooltip="{{ 'settings.summarizationTooltip' | translate }}"
        class="tooltip-icon"
        inline>
        info_outlined
      </mat-icon>
    </span>
  </mat-checkbox>
</p>

<p>
  <mat-checkbox (change)="updateIsHybridSearch($event)">
    <span class="checkbox-label">
      {{ 'settings.hybridSearch' | translate }}
      <mat-icon
        matTooltip="{{ 'settings.hybridSearchTooltip' | translate }}"
        class="tooltip-icon"
        inline>
        info_outlined
      </mat-icon>
    </span>
  </mat-checkbox>
</p>

<p *ngIf="(selectedConfiguration | async)?.searchSettings?.second_index">
  <mat-form-field class="select-field" appearance="outline">
    <mat-label>
      {{ 'settings.useSecondIndex' | translate }}
    </mat-label>
    <mat-select
      (selectionChange)="selectIndex($event)"
      [(ngModel)]="selectedIndex">
      <mat-option [value]="null">
        No focus branch selected
        <!-- {{ 'settings.removeIndex' | translate }} -->
      </mat-option>
      <mat-option [value]="searchIndex.NORTAL_MARKETING_INDEX">
        {{ 'settings.useMarketingIndex' | translate }}
      </mat-option>
      <mat-option [value]="searchIndex.NORTAL_HR_INDEX">
        {{ 'settings.useHrIndex' | translate }}
      </mat-option>
      <mat-option [value]="searchIndex.PB_MARKETING_INDEX">
        {{ 'settings.usePBMarketingIndex' | translate }}
      </mat-option>
      <mat-option [value]="searchIndex.JOHNSTONE_SUPPLY">
        {{ 'settings.useJohnstoneSupply' | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</p>

<app-slider></app-slider>
