import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { StateService } from '../../../core/data-access/state.service';
import { EmailModel } from '../../../core/model/email.model';

@Component({
  selector: 'app-feedback-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackDialogComponent {
  form = new FormGroup({
    fromEmail: new FormControl('', [Validators.email, Validators.required]),
    message: new FormControl('', [Validators.required])
  });

  constructor(
    private stateService: StateService,
    @Inject(MAT_DIALOG_DATA) public data: { feedbackSubject: string }
  ) {}

  sendFeedback() {
    const emailData: EmailModel = {
      fromEmail: this.form.value.fromEmail ?? '',
      subject: this.data.feedbackSubject,
      message: this.form.value.message ?? ''
    };
    this.stateService.sendFeedback(emailData);
  }

  getSendEmailToErrorMessage(): string {
    if (this.form.controls.fromEmail.errors?.['email']) {
      return 'feedback.emailInvalid';
    }
    return 'feedback.emailRequired';
  }
}
