import { ClientName } from 'src/app/shared/model/query-body.model';
import {
  AnswerType,
  Configuration,
  SearchIndex
} from '../configurations.model';

export const NORTAL_GPT: Configuration = {
  title: ClientName.NORTAL_TARK,
  shortName: ClientName.NORTAL_TARK,
  logoFileName: 'nortalGPT.png',
  searchSettings: {
    index: SearchIndex.SHAREPOINT_INDEX,
    second_index: SearchIndex.NORTAL_MARKETING_INDEX,
    answerType: AnswerType.LFGA,
    retriever_number: 6,
    temperature: 0,
    document_chat_index: SearchIndex.DOCUMENT_CHAT_INDEX
  },
  examples: [
    {
      language: 'english',
      flagClass: 'gb',
      questions: [
        'What is Nortal Tark?',
        'How do we help new clients and work towards their success?',
        "Has Nortal contributed to Estonia's digital government experience? If so what exactly?",
        'Has Nortal done any work with health record systems? What were the key takeaways?',
        'Which awards and recognitions have we earned worldwide so far?',
        "Can you give some insights on Nortal's contributions in Germany?",
        "I'm having a kid soon, is there anything on the HR or benefits side I should know about?",
        'How do I apply vacation during Christmas?',
        'Employee benefits of Nortal in Oman?',
        'Can I bring my dog to the office?',
        'Tell me more about home office reimbursements?'
        /*
        'How do you spell the Finnish steel manufacturer that we worked with?'
        'How has Nortal contributed in developing financial services strategies?'
      ] */
      ]
    },
    {
      language: 'german',
      flagClass: 'de',
      questions: [
        'Was ist Nortal Tark?',
        'Wie helfen wir neuen Kunden und arbeiten an ihrem Erfolg?',
        'Hat Nortal zum digitalen Regierungserlebnis Estlands beigetragen? Wenn ja, was genau?',
        'Hat Nortal an Gesundheitsaktensystemen gearbeitet? Was waren die wichtigsten Erkenntnisse?',
        'Welche Auszeichnungen und Anerkennungen haben wir bisher weltweit erhalten?',
        'Können Sie einige Einblicke in die Beiträge von Nortal in Deutschland geben?',
        'Ich bekomme bald ein Kind. Gibt es etwas auf der Personal- oder Sozialleistungsseite, über das ich Bescheid wissen sollte?',
        'Wie beantrage ich Urlaub während der Weihnachtszeit?',
        'Vorteile für die Mitarbeiter von Nortal in Oman?',
        'Kann ich meinen Hund mit ins Büro nehmen?',
        'Erzählen Sie mir mehr über die Erstattungen für das Home Office?'
        /* 
        'Können Sie einige Einblicke in die Beiträge von Nortal in Deutschland geben?',
        'Wie schreiben Sie den finnischen Stahlhersteller, mit dem wir zusammengearbeitet haben?'
        'Wie hat Nortal zur Entwicklung von Finanzdienstleistungsstrategien beigetragen?'
      ] */
      ]
    },
    {
      language: 'estonian',
      flagClass: 'ee',
      questions: [
        'Mis on Nortal Tark?',
        'Kuidas me aitame uusi kliente ja töötame nende edu nimel?',
        'Kas Nortal on aidanud kaasa Eesti digitaalse valitsuse kogemusele? Kui jah, siis mida täpselt?',
        'Kas Nortal on töötanud terviseandmete süsteemidega? Millised olid peamised järeldused?',
        'Milliseid auhindu ja tunnustusi oleme seni maailmas pälvinud?',
        'Kas te saate anda ülevaate Nortali panusest Saksamaal?',
        'Saan varsti lapse - kas on midagi, mida peaksin teadma personali- või hüvitiste poolel?',
        'Kuidas taotleda puhkust jõulude ajal?',
        'Nortali töötajate hüvitised Omaanis?',
        'Kas ma võin oma koera kontorisse kaasa võtta?',
        'Räägi mulle rohkem kodukontori hüvitistest?'
        /* 'Kuidas on Nortal aidanud kaasa finantsteenuste strateegiate arendamisele?' */
      ]
    },
    {
      language: 'arabic',
      flagClass: 'ae',
      questions: [
        'ما هو نورتال تارك؟',
        'كيف نساعد العملاء الجدد ونعمل على نجاحهم؟',
        'هل ساهمت شركة نورتال في تجربة الحكومة الرقمية في إستونيا؟ إذا كان الأمر كذلك، ماذا بالضبط؟',
        'ما هي الجوائز والتقديرات التي حصلنا عليها في جميع أنحاء العالم حتى الآن؟',
        'هل عملت شركة Nortal مع أنظمة السجلات الصحية؟ ما هي الوجبات الرئيسية؟',
        'هل يمكنك تقديم بعض الأفكار حول مساهمات نورتال في ألمانيا؟',
        'سأرزق بطفل قريبًا، هل هناك أي شيء يجب أن أعرفه فيما يتعلق بالموارد البشرية أو المزايا؟',
        'كيف يمكنني التقدم بطلب للحصول على إجازة خلال عيد الميلاد؟',
        'فوائد موظفي نورتال في عمان؟',
        'هل يمكنني إحضار كلبي إلى المكتب؟',
        'هل تريد المزيد من فرص العمل في وزارة الداخلية؟'
        /* 'كيف ساهمت نورتال في تطوير استراتيجيات الخدمات المالية؟' */
      ]
    }
  ]
};
