import { Answer } from '../../../shared/model/answer.model';

export interface UserMessage {
  user: string;
  userTimestamp: string | null;
}

export interface BotMessage {
  bot: Answer | null;
  botTimestamp: string | null;
}

export interface ChatMessage {
  userMessage: UserMessage;
  botMessage: BotMessage;
}

export class ChatMessage implements ChatMessage {}

export interface ShortenedMessage {
  user: string;
  bot: string | undefined;
}
