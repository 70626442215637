import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EmailModel, EmailResponse } from '../../../core/model/email.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private httpClient: HttpClient) {}

  sendEmail(emailData: EmailModel) {
    return this.httpClient.post<EmailResponse>(
      environment.azureLogicAppUrl,
      emailData
    );
  }
}
