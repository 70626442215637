import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChatMessage } from '../model/chat-message.model';
import { CommonModule } from '@angular/common';
import { QueryFieldComponent } from '../../../shared/ui/query-field/query-field.component';
import { StateService } from '../../../core/data-access/state.service';
import { ChatMessageComponent } from '../../../shared/ui/chat-message/chat-message.component';
import { MatButtonModule } from '@angular/material/button';
import { LoaderService } from 'src/app/core/data-access/loader.service';
import { TranslateModule } from '@ngx-translate/core';
import { ChatbotService } from '../data-access/chatbot.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-chatbot-feature',
  standalone: true,
  imports: [
    CommonModule,
    QueryFieldComponent,
    MatProgressSpinnerModule,
    ChatMessageComponent,
    MatButtonModule,
    TranslateModule
  ],
  templateUrl: './chatbot-feature.component.html',
  styleUrls: ['./chatbot-feature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatbotFeatureComponent implements OnInit, AfterViewChecked {
  @ViewChild('chatContainer') chatContainer: ElementRef;

  @Input() selectedFileNames: string[] | undefined;
  @Input() classes: string | undefined;

  chatbotService = inject(ChatbotService);
  private stateService = inject(StateService);
  private loaderService = inject(LoaderService);

  taskIds$ = this.stateService.getTaskIdsStream();
  taskIds: string[] = [];

  chat$ = this.stateService.chat$;
  exampleQuestion$ = this.stateService.exampleQuestion$;
  isLoading$ = this.loaderService.isLoading$;

  destroyRef = inject(DestroyRef);

  chatMessages: ChatMessage[];

  ngOnInit() {
    if (this.selectedFileNames?.length) {
      this.stateService.setEmptyChatHistory();
    } else {
      this.stateService.loadChatHistory();
    }

    this.taskIds$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(ids => {
      this.taskIds = ids;
    });
  }

  ngAfterViewChecked() {
    this.chatContainer.nativeElement.scrollTop =
      this.chatContainer.nativeElement.scrollHeight;
  }

  onSendMessage(message: string) {
    this.stateService.chat(message, this.selectedFileNames, this.taskIds);
    // this.stateService.makeChatQuery(message);
  }

  clearHistory(): void {
    this.stateService.clearChatHistory();
  }
}
