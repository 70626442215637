import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MarkdownModule } from 'ngx-markdown';

@Component({
  selector: 'app-description-dialog',
  standalone: true,
  imports: [CommonModule, MarkdownModule, MatDialogModule, MatButtonModule],
  templateUrl: './description-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DescriptionDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      backend: string;
      version: string;
      description: string;
      closeButtonText: string;
    }
  ) {}
}
