import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  IndustryType,
  Sector,
  ServiceOffering
} from 'src/app/shared/model/query-filter.model';
import { environment } from 'src/environments/environment';
import { SearchIndex } from '../model/configurations.model';
import { AccessTokenService } from './access-token.service';

export interface PostUploadResponse {
  status_code?: number;
  message?: string;
  taskId?: string;
}

export interface GetUploadResponse {
  status_code?: number;
  message?: string;
  task_id?: string;
}

export interface GetProposalResponse {
  status_code: number;
  message: string;
  url: string;
  results: [timestamp?: Date | string, message?: string];
}

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private accessTokenService = inject(AccessTokenService);
  private http = inject(HttpClient);

  uploadFile(files: File[]): Observable<File> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });
    formData.append('index', SearchIndex.DOCUMENT_CHAT_INDEX);
    formData.append('model', 'gpt-35-turbo');

    return this.http.post<File>(
      `${environment.ingestionBackendUrl}/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.accessTokenService.accessToken$.value
        }
      }
    );
  }

  postUploadFiles(files: File[]): Observable<PostUploadResponse> {
    const formData = new FormData();
    files.forEach((file: File) => {
      formData.append('files', file);
    });
    formData.append('index', SearchIndex.DOCUMENT_CHAT_INDEX);
    formData.append('model', 'gpt-35-turbo');

    return this.http.post<PostUploadResponse>(
      `${environment.ingestionBackendUrl}/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.accessTokenService.accessToken$.value
        }
      }
    );
  }

  getUploadFiles(task_id: string): Observable<GetUploadResponse> {
    return this.http.get<GetUploadResponse>(
      `${environment.ingestionBackendUrl}/upload`,
      {
        params: { task_id },
        headers: {
          Authorization: 'Bearer ' + this.accessTokenService.accessToken$.value
        }
      }
    );
  }

  postRfpFile(file: File): Observable<PostUploadResponse> {
    const formData = new FormData();
    formData.append('index', SearchIndex.PROPOSAL_GENERATOR_INDEX);
    formData.append('model', 'gpt4-32k');
    if (file) {
      formData.append('file', file);
    }

    return this.http.post<PostUploadResponse>(
      `${environment.haystackBackendUrl}/proposal_generator`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.accessTokenService.accessToken$.value
        }
      }
    );
  }

  getProposal(task_id: string) {
    const response = this.http.get<GetProposalResponse>(
      `${environment.haystackBackendUrl}/proposal_generator`,
      {
        params: { task_id },
        headers: {
          Authorization: 'Bearer ' + this.accessTokenService.accessToken$.value
        }
      }
    );
    return response;
  }

  getSectors(): Observable<string[]> {
    return of([Sector.PRIVATE, Sector.GOVERNMENT, Sector.OTHER]);
  }

  getIndustryTypes(): Observable<string[]> {
    return of([
      IndustryType.MANUFACTURING,
      IndustryType.ENERGY,
      IndustryType.RETAIL,
      IndustryType.PUBLIC_SERVICES,
      IndustryType.UTILITIES,
      IndustryType.TRANSPORTATION,
      IndustryType.ENVIRONMENTAL_PROTECTION,
      IndustryType.TECHNOLOGY,
      IndustryType.FINANCE,
      IndustryType.INSURANCE,
      IndustryType.PUBLIC_FINANCE,
      IndustryType.TRAVEL,
      IndustryType.CHAMBERS,
      IndustryType.ECONOMIC_AFFAIRS,
      IndustryType.HEALTHCARE,
      IndustryType.HEALTH,
      IndustryType.EDUCATION,
      IndustryType.SAFETY,
      IndustryType.PROFESSIONAL_SERVICES,
      IndustryType.AGRICULTURE,
      IndustryType.OTHER
    ]);
  }

  getServiceOfferings(): Observable<string[]> {
    return of([
      ServiceOffering.DATA,
      ServiceOffering.SOFTWARE,
      ServiceOffering.TECHNOLOGY_CONSULTING,
      ServiceOffering.CLOUD_MIGRATION,
      ServiceOffering.SYSTEM_INTEGRATION,
      ServiceOffering.TRANSFORMATION,
      ServiceOffering.PRODUCT_DESIGN,
      ServiceOffering.PRODUCT_MANAGEMENT,
      ServiceOffering.PRODUCT_CX,
      ServiceOffering.PROJECT_MANAGEMENT,
      ServiceOffering.STRATEGIC_CONSULTING,
      ServiceOffering.ENGINEERING,
      ServiceOffering.LIFECYCLE_SERVICES
    ]);
  }
}
