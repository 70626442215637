<mat-toolbar color="primary">
  <div [matMenuTriggerFor]="configurationsMenu" class="logo-title-container">
    <img [src]="logoPath$ | async" alt="Logo" />
    <span>{{ title$ | async }}</span>
  </div>

  <mat-menu #configurationsMenu="matMenu" class="mat-menu">
    <button
      *ngFor="let configuration of configurations$ | async"
      mat-menu-item
      (click)="updateConfiguration(configuration)">
      {{ configuration.shortName }}
    </button>
  </mat-menu>

  <span class="spacer"></span>

  <div *ngIf="isTabletLandscapeUp$ | async" class="button-row">
    <button mat-button (click)="openDialog(requestFeature)">
      {{ 'feedback.requestFeature' | translate }}
    </button>
    <button mat-flat-button color="warn" (click)="openDialog(reportBug)">
      {{ 'feedback.reportBug' | translate }}
    </button>
  </div>

  <app-lang-switcher></app-lang-switcher>

  <button class="user-menu" [matMenuTriggerFor]="userMenu">
    <div class="initial-icon">
      <mat-icon>person</mat-icon>
    </div>
    <ng-container *ngIf="activeAccountName as name">
      {{ name }}
    </ng-container>
    <ng-container *ngIf="isLogin">
      {{ 'header.clickToLogin' | translate }}
    </ng-container>
  </button>

  <mat-menu #userMenu="matMenu" xPosition="before">
    <button
      mat-menu-item
      (click)="changeAuth(AuthOperation.LOGIN)"
      *ngIf="isLogin">
      {{ 'header.logIn' | translate }}
    </button>
    <button
      mat-menu-item
      *ngIf="!isLogin"
      (click)="changeAuth(AuthOperation.LOGOUT)">
      {{ 'header.logOut' | translate }}
    </button>
  </mat-menu>
</mat-toolbar>
