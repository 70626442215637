export interface QueryFilter {
  sector: string[] | undefined;
  industry_type: string[] | undefined;
  service_offering: string[] | undefined;
  name?: string[] | undefined;
  task_id?: string[] | undefined;
}

export enum Sector {
  PRIVATE = 'Private',
  GOVERNMENT = 'Government',
  OTHER = 'Other'
}

export enum IndustryType {
  MANUFACTURING = 'Manufacturing & Mining',
  ENERGY = 'Energy & Fossil Fuels',
  RETAIL = 'Retail & CPG',
  PUBLIC_SERVICES = 'General public services',
  UTILITIES = 'Utilities',
  TRANSPORTATION = 'Transportation & Logistics',
  ENVIRONMENTAL_PROTECTION = 'Environmental protection',
  TECHNOLOGY = 'Technology',
  FINANCE = 'Finance',
  INSURANCE = 'Insurance and Fintech',
  PUBLIC_FINANCE = 'Public finance management',
  TRAVEL = 'Travel & Hospitality',
  CHAMBERS = 'Chambers (Commerce and Industry)',
  ECONOMIC_AFFAIRS = 'Economic affairs (industries)',
  HEALTHCARE = 'Healthcare & Pharmaceuticals',
  HEALTH = 'Health',
  EDUCATION = 'Education & Research',
  OTHER = 'Other',
  SAFETY = 'Public order and safety',
  PROFESSIONAL_SERVICES = 'Professional Services',
  AGRICULTURE = 'Agriculture'
}

export enum ServiceOffering {
  DATA = 'Data & AI',
  SOFTWARE = 'Custom Software & DevOps',
  TECHNOLOGY_CONSULTING = 'Technology Consulting',
  CLOUD_MIGRATION = 'Cloud Migration & Transformation',
  SYSTEM_INTEGRATION = 'SaaS and System Integration 9',
  TRANSFORMATION = 'Transformation & Change Consulting',
  PRODUCT_DESIGN = 'Product Design',
  PRODUCT_MANAGEMENT = 'Product Management',
  PRODUCT_CX = 'Product & CX',
  PROJECT_MANAGEMENT = 'Project Management',
  STRATEGIC_CONSULTING = 'Strategic Consulting',
  ENGINEERING = 'Engineering',
  LIFECYCLE_SERVICES = 'Lifecycle Services'
}

export enum QueryFilterType {
  SECTOR = 'sector',
  INDUSTRY_TYPE = 'industry_type',
  SERVICE_OFFERING = 'service_offering'
}
