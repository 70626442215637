import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { ReleaseNote } from '../../model/release-notes.model';
import { DescriptionDialogComponent } from 'src/app/shared/ui/description-dialog/description-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ReleaseNoteService } from '../../data-access/release-notes.service';

@Component({
  selector: 'app-release-notes',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    DescriptionDialogComponent
  ],
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit {
  private releaseNotesService = inject(ReleaseNoteService);
  private translate = inject(TranslateService);
  private dialog = inject(MatDialog);

  releaseNotes$: BehaviorSubject<ReleaseNote[] | undefined> =
    this.releaseNotesService.releaseNotes$;

  ngOnInit() {
    this.releaseNotesService.handleReleaseNotes();
  }

  openModal(rNote: ReleaseNote) {
    this.dialog.open(DescriptionDialogComponent, {
      width: '800px',
      data: {
        title: this.translate.instant('releaseNotes.title'),
        version: this.translate.instant('version') + ' ' + rNote.version,
        backend: rNote.backend,
        description: rNote.description,
        closeButtonText: this.translate.instant('buttons.close')
      },
      autoFocus: false,
      maxHeight: '90vh'
    });
  }
}
