import { ClientName } from 'src/app/shared/model/query-body.model';
import {
  AnswerType,
  Configuration,
  SearchIndex
} from '../configurations.model';

export const CALDWELL_COUNTY_INDEX: Configuration = {
  title: ClientName.CALDWELL_COUNTY_INDEX,
  shortName: ClientName.CALDWELL_COUNTY_INDEX,
  logoFileName: 'caldwell.png',
  searchSettings: {
    index: SearchIndex.CALDWELL_COUNTY_INDEX,
    answerType: AnswerType.LFGA,
    retriever_number: 6,
    temperature: 0,
    document_chat_index: SearchIndex.DOCUMENT_CHAT_INDEX
  },
  examples: [
    {
      language: 'english',
      flagClass: 'us',
      questions: [
        'How do I get my water account setup?',
        'When are the property taxes due?',
        'What kind of building permits are available?',
        'What do I need to know about vehicle taxing?',
        'What permits are needed for making renovations to my house?',
      ]
    }
  ]
};
