import {
  SearchIndex,
  AnswerType
} from 'src/app/core/model/configurations.model';
import { QueryFilter } from './query-filter.model';

export interface QueryBody {
  index?: SearchIndex;
  model?: string | ModelType;
  answer_type?: AnswerType;
  user_query: string;
  user_id: string;
  retriever_number?: number;
  generator_number?: number;
  temperature?: number;
  stream?: boolean;
  max_length?: number;
  split_length?: number;
  language?: string;
  debug?: string;
  summarization?: boolean;
  hybrid_search?: boolean;
  second_index?: string;
  chat_history?: ChatHistory[];
  filters?: Partial<QueryFilter>;
  top_k?: number;
}

export interface ChatHistory {
  user: string;
  bot: string | undefined;
}

export interface QuerySettings {
  model: string | ModelType;
  index: SearchIndex;
  answerType: AnswerType;
  retriever_number: number;
  temperature: number;
  stream?: boolean;
  isSummarization?: boolean;
  isHybridSearch?: boolean;
  user_query?: string;
  second_index?: string;
  top_k?: number;
}

export enum ModelType {
  //   GPT3_Turbo_16k = 'gpt-35-turbo_16k_us',
  //   GPT3_Turbo = 'OpenAI GPT-3.5 Turbo',
  //   GPT4 = 'OpenAI GPT-4',
  GPT3_Turbo_16k = 'gpt-35-turbo-16k',
  GPT3_Turbo = 'gpt-35-turbo',
  GPT4 = 'gpt-4',
  GPT4_Turbo = 'gpt-4-turbo'
  //  BART = 'bart'
}

export enum ClientName {
  NORTAL_TARK = 'Nortal Tark',
  KSA = 'KSA Intelligent Search',
  EE = 'EE Intelligent Search',
  ESG = 'ESG Intelligent Search',
  JATHEON = 'Jatheon Intelligent Search',
  OUTUKUMPU = 'Outukumpu Intelligent Search',
  KONECRANES = 'Konecranes Intelligent Search',
  NLGA = 'NLGA Intelligent Search',
  ISBJ = 'ISBJ Intelligent Search',
  TELE2 = 'TELE2 Intelligent Search',
  SCAD = 'SCAD Intelligent Search',
  SW = 'SparkasseGPT',
  BESCHA = 'BeschA Intelligent Search',
  KSA_MISA = 'MISA Intelligent Search',
  BERLINER_VOLKSBANK = 'Volksbank Smart Helper',
  WM_NIEDERSACHSEN = 'Niedersachsen Intelligent Search',
  PIF = 'PIF Intelligent Search',
  AI_WORKSHOP = 'AI Workshop',
  AVEATO = 'Aveato Intelligent Caterer',
  JOHNSTONE_SUPPLY = 'Johnstone Supply',
  BUNDESAGENTUR_FUER_ARBEIT = 'Bundesagentur Für Arbeit',
  BLIGGIT = 'Bliggit KI',
  ENVIR = 'Keskkonnaamet',
  PWC = 'PwC',
  CALDWELL_COUNTY_INDEX = 'Caldwell County',
  BOSCH_HEALTH_INDEX = 'Bosch Health',
  MUKILTEO_COUNTY_INDEX = 'City of Mukilteo'
}
