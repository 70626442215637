import { ClientName } from 'src/app/shared/model/query-body.model';
import {
  AnswerType,
  Configuration,
  SearchIndex
} from '../configurations.model';

export const JOHNSTONE_SUPPLY: Configuration = {
  title: ClientName.JOHNSTONE_SUPPLY,
  shortName: ClientName.JOHNSTONE_SUPPLY,
  logoFileName: 'johnstone_supply.svg',
  searchSettings: {
    index: SearchIndex.JOHNSTONE_SUPPLY,
    answerType: AnswerType.LFGA,
    retriever_number: 6,
    temperature: 0,
    document_chat_index: SearchIndex.DOCUMENT_CHAT_INDEX
  },
  examples: [
    {
      language: 'english',
      flagClass: 'gb',
      questions: [
        'What safety measures should I follow when purging gas lines before starting up a Goodman gas furnace?',
        'How I determine the correct blower speed settings for different heating and cooling modes on the GM9S800603 furnace?',
        'What is an acceptable range of static duct pressure for furnace ​________?',
        'How should I measure the duct pressure and verify proper airflow in the system?',
        'How do I adjust the gas manifold pressure on​ ________ furnance and are there precautions I should take?',
        'How do I adjust the fan on/off delay times and what are the recommended settings?'
      ]
    }
  ]
};
