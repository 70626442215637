export interface Answer {
  userQuery?: string;
  runtimeInMillis?: number;
  index: string;
  answer: string;
  answers: Document[];
  sources: Source[];
  prompt?: string | null;
  error_code?: number | null;
}

export class Answer implements Answer {}

export interface RequestState {
  userQuery: string;
  documents: Document[];
  answerText: string;
  runtimeInMillis?: number;
  sources: Source[];
  stream: boolean;
  requestInProgress: boolean;
  hasProcessedDocs: boolean;
  error_code: any;
  prompt: any;
  index: any;
}

// TODO ask BE to define a model. Currently it is not defined anywhere
export interface Document {
  content: string;
  content_type: string;
  score: string;
  documents?: Document[];
  meta: {
    link: string;
    name: string;
    _split_id: number;
    base_url: string;
    url: string;
    score?: string;
  };
  id_hash_keys: string[];
  id: string;
}

export interface Source {
  name: string;
  link: string;
}

export interface OpenSourceLLMAnswer {
  answer: string;
  context: string;
}
