import { ClientName } from 'src/app/shared/model/query-body.model';
import {
  AnswerType,
  Configuration,
  SearchIndex
} from '../configurations.model';

export const BOSCH_HEALTH_INDEX: Configuration = {
  title: ClientName.BOSCH_HEALTH_INDEX,
  shortName: ClientName.BOSCH_HEALTH_INDEX,
  logoFileName: 'bosch.png',
  searchSettings: {
    index: SearchIndex.BOSCH_HEALTH_INDEX,
    answerType: AnswerType.LFGA_GOOGLE,
    retriever_number: 6,
    temperature: 0,
    document_chat_index: SearchIndex.DOCUMENT_CHAT_INDEX
  },
  examples: [
    {
      language: 'english',
      flagClass: 'gb',
      questions: [
        'What is type 1 diabetes?',
        'What are the symptoms of type 1 diabetes?',
        'What is the difference between type 1 and type 2 diabetes?',
        'What is the treatment for type 2 diabetes?',
        'What are risk factors for getting type 2 diabetes?',
        'What is gestational diabetes?',
        'What are the symptoms of gestational diabetes?'
      ]
    }
  ]
};
